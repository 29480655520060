import React, { useState } from 'react';
import { Drawer, Button, Modal, Typography, Space, Divider } from 'antd';
import request from '../../../Components/net/request';
import ImportStudentsModal from './ImportStudentsModal';
import './CourseInfoDrawer.scss';

const { Title, Text } = Typography;

const CourseInfoDrawer = ({ 
    open, 
    onClose, 
    courseData,
    onDeleted  // 添加新的回调属性用于刷新列表
}) => {
    const [importModalVisible, setImportModalVisible] = useState(false);

    const handleDeleteCourse = async () => {
        try {
            const response = await request.post('/api/v1/course/delete', {
                uuid: courseData.uuid
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.code === 200) {
                onClose(); // 关闭抽屉
                onDeleted(); // 刷新课程列表
            } else {
                Modal.error({
                    title: '删除失败',
                    content: response.data.message || '请稍后重试'
                });
            }
        } catch (error) {
            Modal.error({
                title: '删除失败',
                content: '网络错误，请稍后重试'
            });
        }
    };

    return (
        <Drawer 
            title={<Title level={4} style={{ margin: 0 }}>班级信息</Title>}
            onClose={onClose}
            open={open}
            width={400}
            className="course-info-drawer"
        >
            <div className="course-info-content">
                <div className="info-section">
                    <Space direction="vertical" size="large" style={{ width: '100%' }}>
                        <div className="info-item">
                            <Text type="secondary">班级名称</Text>
                            <Title level={5}>{courseData.name}</Title>
                        </div>
                        
                        <div className="info-item">
                            <Text type="secondary">班级号</Text>
                            <Title level={5} copyable>{courseData.unique_code}</Title>
                        </div>

                        <div className="info-item">
                            <Text type="secondary">创建时间</Text>
                            <Title level={5}>{courseData.created_at || '暂无'}</Title>
                        </div>
                    </Space>
                </div>

                <Divider />

                <div className="info-section">
                    <Title level={5}>班级管理</Title>
                    <Space direction="vertical" style={{ width: '100%' }} size="middle">
                        <Button 
                            block
                            onClick={() => setImportModalVisible(true)}
                        >
                            导入学生
                        </Button>
                        <Button block>
                            管理班级成员
                        </Button>
                    </Space>
                </div>
            </div>
            
            <div className="drawer-footer">
                <Button 
                    danger
                    type="primary"
                    block
                    size="large"
                    onClick={() => {
                        Modal.confirm({
                            title: '确认解散班级',
                            content: '解散班级后，所有数据将无法恢复，是否确认解散？',
                            okText: '确认解散',
                            cancelText: '取消',
                            okButtonProps: { danger: true },
                            onOk: handleDeleteCourse
                        });
                    }}
                >
                    解散班级
                </Button>
            </div>

            <ImportStudentsModal
                open={importModalVisible}
                onClose={() => setImportModalVisible(false)}
                courseUuid={courseData.uuid}
            />
        </Drawer>
    );
};

export default CourseInfoDrawer; 