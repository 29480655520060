import React, {useContext, useState} from 'react';
import {Button, Input, Modal, message} from "antd";
import {DataContext} from "../../DataProvider";
import request from "../../../Components/net/request";
import OSSUpload from "../../../Components/net/OSSUpload";

const CreateCourse = ({getClassList}) => {
    const {createCourseDialog, setCreateCourseDialog} = useContext(DataContext);
    const [classNameText, setClassNameText] = useState("");
    const [isDisabled, setIsDisabled] = useState(false);
    const [uploadedImageUrl, setUploadedImageUrl] = useState('');

    const DEFAULT_COVER_IMAGE = 'http://common-saas.oss-cn-beijing.aliyuncs.com/course/cover/1743238144556-%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20250329164843.png';

    const handleOk = () => {
        setIsDisabled(true);

        request.post("/api/v1/course/regular/create", {
            "name": classNameText,
            "cover_image": uploadedImageUrl || DEFAULT_COVER_IMAGE
        }).then(response => {
            setCreateCourseDialog(false);
            getClassList();
        }).catch((err)=>{
            setIsDisabled(false);
        });
    };

    const handleCancel = () => {
        setCreateCourseDialog(false);
    };

    function onTextChange(e) {
        setClassNameText(e.target.value);
    }

    return (
        <Modal
            open={createCourseDialog}
            title="创建班级"
            onOk={handleOk}
            onCancel={handleCancel}
            transitionName=""
            maskTransitionName=""
            mask={false}
            footer={[
                <Button key="back" onClick={handleCancel}>取消</Button>,
                <Button key="submit" type="primary" onClick={handleOk} disabled={isDisabled}>
                    提交
                </Button>,
            ]}
        >
            <div className="dialog_line"></div>
            <div className="class_normal">班级名称</div>
            <Input 
                placeholder="请输入不超过40个字的班级名称" 
                onChange={onTextChange}
            />
            <div className="class_normal">选择班级头像</div>
            <OSSUpload
                folder="course/cover"
                accept="image/*"
                onSuccess={setUploadedImageUrl}
            >
                + Upload
            </OSSUpload>
        </Modal>
    );
};

export default CreateCourse;
