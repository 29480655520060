import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import './BindLessonVideo.css';
import Util from "../../../../src/LivePage/Util";
import request from '../../../../src/Components/net/request';
import qs from 'qs';

const BindLessonVideo = ({ onClose, courseData, refreshLesson }) => {
    const [selectedName, setSelectedName] = useState('');
    const [selectedExtension, setSelectedExtension] = useState('');
    const url = `https://saas.xiaoyunduoyuan.com/mydesktop?openaction=for-class&type=2&token=${window.user_token}&userid=${Util.getUrlParam('uid')}`;

    useEffect(() => {
        // 添加事件监听
        const handleSelectedVideo = (path, name, extension) => {
            window.selected_video_path = path;
            window.selected_video_name = name;
            window.selected_video_extension = extension;
            setSelectedName(name);
            setSelectedExtension(extension);
            console.log("handleSelectedVideo: ", window.selected_video_path, window.selected_video_name, window.selected_video_extension);
        };

        window.CallBridge.addEventListener("selected_video", handleSelectedVideo);

        // 清理函数
        return () => {
            window.CallBridge.removeEventListener("selected_video", handleSelectedVideo);
        };
    }, []);

    const handleConfirm = async () => {
        try {
            const response = await request.post('/api/v1/course/lesson/replay',
                qs.stringify({
                    uuid: courseData.uuid,
                    replay_url: window.selected_video_path
                }), {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            );

            if (response.data.code === 200) {
                message.success('绑定成功');
                onClose();
                refreshLesson();
            } else {
                message.error('绑定失败：' + response.data.message);
            }
        } catch (error) {
            message.error('绑定失败：' + (error.message || '未知错误'));
        }
    };

    return (
        <div className="bind-course-container">
            <div className="bind-iframe-section">
                <iframe
                    src={url}
                    className="bind-iframe"
                    frameBorder="0"
                />
            </div>
            <div className="bind-button-section">
                {selectedName && <span className="selected-name">已选择：{selectedName}{selectedExtension ? '.' + selectedExtension : ''}</span>}
                <Button onClick={handleConfirm}>确定所选</Button>
            </div>
        </div>
    );
};

export default BindLessonVideo;
