import {UserInfo} from "./Context";

function getUrlParams(paramName) {
    const params = {};
    const queryString = window.location.search.substring(1);
    const pairs = queryString.split("&");

    for (const pair of pairs) {
        const [key, value] = pair.split("=");
        params[decodeURIComponent(key)] = decodeURIComponent(value);
    }

    if (paramName) {
        return params[paramName];
    }

    return params;
}

const Util = {
    guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
        });
    },

    getUrlParam(key) {
        //const urlParams = new URLSearchParams(window.location.search);
        return getUrlParams(key);
    },

    getPushUrl() {
        const uid = window.user_info.user.id;
        return "webrtc://push.xiaoyunduoyuan.com/live/s_" + uid;
    },

    getMainUrl() {
        const id = window.course_info.uuid;
        return 'webrtc://play.xiaoyunduoyuan.com/live/t_' + id;
    },

    getChatUrl() {
        const id = window.course_info.uuid;
        return 'wss://sig.xiaoyunduoyuan.com:20873/xychat/' + id;
    },

    getSignalUrl() {
        const id = window.course_info.uuid;
        return 'wss://sig.xiaoyunduoyuan.com:20874/xysig/' + id;
    },

    getUserId() {
        return window.user_info.user.id;
    },

    getUserName() {
        return window.user_info.user.nickname;
    },
}

export default Util;
