import React, { useEffect, useState } from 'react';
import { Avatar, Button, message, Input, Modal, Tabs } from 'antd';
import { EditOutlined, LogoutOutlined } from '@ant-design/icons';
import request from '../../Components/net/request';
import OSSUpload from '../../Components/net/OSSUpload';  // 导入 OSSUpload 组件
import AvatarUploadModal from './AvatarUploadModal';  // 导入新组件
import './UserProfile.css';

const DEFAULT_AVATAR = 'http://common-saas.oss-cn-beijing.aliyuncs.com/course/cover/1743323957020-default_avatar.png'; // 添加默认头像路径

const UserProfile = ({ onClose }) => {
    const [userInfo, setUserInfo] = useState({
        avatar: '',
        nickname: '',
        email: '未绑定',
        phone: '',
        type: 0,
        state: 0,
        prov: '',
        city: '',
        area: '',
        created_at: '',
        updated_at: ''
    });
    const [isEditingNickname, setIsEditingNickname] = useState(false);
    const [tempNickname, setTempNickname] = useState('');
    const [isUploadVisible, setIsUploadVisible] = useState(false);

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const fetchUserInfo = async () => {
        try {
            const response = await request.get('/api/v1/user/me');
            if (response.data.code === 200 && response.data.data) {
                setUserInfo(response.data.data);
            } else {
                message.error('获取用户信息失败');
            }
        } catch (error) {
            message.error('获取用户信息失败');
        }
    };

    // 格式化手机号显示
    const formatPhone = (phone) => {
        if (!phone) return '';
        return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
    };

    // 格式化地区显示
    const formatRegion = () => {
        const { prov, city, area } = userInfo;
        if (!prov && !city && !area) return '未设置';
        return [prov, city, area].filter(Boolean).join(' ');
    };

    // 添加时间格式化函数
    const formatDateTime = (dateTimeString) => {
        if (!dateTimeString) return '';
        const date = new Date(dateTimeString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    const handleNicknameEdit = () => {
        setTempNickname(userInfo.nickname || '');
        setIsEditingNickname(true);
    };

    const handleNicknameSubmit = async () => {
        // 验证昵称格式（2-20个字符，支持中英文、数字、下划线和减号）
        if (!/^[\u4e00-\u9fa5a-zA-Z0-9_-]{2,20}$/.test(tempNickname)) {
            message.error('昵称长度为2-20个字符，支持中英文、数字、下划线和减号');
            return;
        }

        try {
            const formData = new URLSearchParams();
            formData.append('nickname', tempNickname);
            formData.append('avatar', userInfo.avatar || DEFAULT_AVATAR);  // 使用默认头像

            const response = await request.post('/api/v1/user/update', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            if (response.data.code === 200) {
                setUserInfo(prev => ({
                    ...prev,
                    nickname: tempNickname
                }));
                message.success('昵称修改成功');
            } else {
                message.error(response.data.msg || '修改失败');
            }
        } catch (error) {
            message.error('修改失败，请稍后重试');
        } finally {
            setIsEditingNickname(false);
        }
    };

    // 处理头像上传成功
    const handleAvatarUpload = async (url) => {
        try {
            const formData = new URLSearchParams();
            formData.append('nickname', userInfo.nickname || '');
            formData.append('avatar', url);

            const response = await request.post('/api/v1/user/update', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            if (response.data.code === 200) {
                setUserInfo(prev => ({
                    ...prev,
                    avatar: url
                }));
                message.success('头像更新成功');
                setIsUploadVisible(false);  // 只在确认后关闭窗口
            } else {
                message.error(response.data.msg || '更新失败');
            }
        } catch (error) {
            message.error('更新失败，请稍后重试');
        }
    };

    const handleLogout = () => {
        // 使用 CallBridge 调用 C++ 方法
        if (window.CallBridge) {
            window.CallBridge.invokeMethod("quit_app", "", {});
        }
    };

    return (
        <div className="user-profile">
            <div className="profile-header">
                <div className="avatar-section">
                    <div className="avatar-container">
                        <Avatar 
                            size={64} 
                            src={userInfo.avatar || DEFAULT_AVATAR} 
                            className="avatar"
                            onClick={() => setIsUploadVisible(true)}
                            style={{ cursor: 'pointer' }}
                        >
                            {!userInfo.avatar && !DEFAULT_AVATAR && userInfo.nickname?.[0]}
                        </Avatar>
                    </div>
                    <AvatarUploadModal
                        visible={isUploadVisible}
                        onCancel={() => setIsUploadVisible(false)}
                        onSuccess={(url) => {
                            handleAvatarUpload(url);
                            setIsUploadVisible(false);
                        }}
                        currentAvatar={userInfo.avatar}
                        defaultAvatar={DEFAULT_AVATAR}
                    />
                    <div className="user-info">
                        <div className="nickname-section">
                            {isEditingNickname ? (
                                <Input
                                    value={tempNickname}
                                    onChange={e => setTempNickname(e.target.value)}
                                    onBlur={handleNicknameSubmit}
                                    onPressEnter={handleNicknameSubmit}
                                    autoFocus
                                    maxLength={20}
                                    style={{ width: '200px' }}
                                />
                            ) : (
                                <div className="nickname-display">
                                    <span className="nickname">{userInfo.nickname || '未设置'}</span>
                                    <EditOutlined 
                                        className="edit-icon"
                                        onClick={handleNicknameEdit}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="profile-content">
                <div className="profile-item">
                    <span className="item-label">手机</span>
                    <div className="item-value">
                        <span className="verified">{formatPhone(userInfo.wx_openid)}</span>
                    </div>
                </div>
                <div className="profile-item">
                    <span className="item-label">邮箱</span>
                    <div className="item-value">
                        <span>{userInfo.email || '未绑定'}</span>
                        <Button type="link" className="edit-button">绑定</Button>
                    </div>
                </div>
                <div className="profile-item">
                    <span className="item-label">注册时间</span>
                    <span className="item-value">{formatDateTime(userInfo.created_at)}</span>
                </div>
                <div className="logout-section">
                    <Button 
                        type="text" 
                        className="logout-button"
                        icon={<LogoutOutlined />}
                        size="large"
                        onClick={handleLogout}
                    >
                        <span className="logout-text">退出登录</span>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default UserProfile; 
