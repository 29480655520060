import React, { useState, useEffect } from 'react';
import { Upload, message } from 'antd';
import OSS from 'ali-oss';
import request from './request';

const OSSUpload = ({
    children,
    onChange,
    folder = 'default', // 上传文件夹路径
    maxCount = 1,
    listType = 'picture-card',
    accept,
    onSuccess // 上传成功的回调
}) => {
    const [stsToken, setStsToken] = useState(null);
    const [ossClient, setOssClient] = useState(null);
    const [fileList, setFileList] = useState([]);

    // 获取 STS 令牌
    const getSTSToken = async () => {
        try {
            const response = await request.get('/api/v1/app/config');
            if (response.data.code === 200) {
                const stsData = response.data.data.oss_config[0];
                const sts_credential = stsData.sts_credential;
                setStsToken({
                    accessKeyId: sts_credential.access_key_id,
                    accessKeySecret: sts_credential.access_key_secret,
                    securityToken: sts_credential.security_token,
                    expiration: sts_credential.expiration,
                });

                const client = new OSS({
                    region: stsData.region,
                    accessKeyId: sts_credential.access_key_id,
                    accessKeySecret: sts_credential.access_key_secret,
                    stsToken: sts_credential.security_token,
                    bucket: stsData.bucket
                });
                setOssClient(client);
                return client;
            }
            throw new Error('获取 STS 令牌失败');
        } catch (error) {
            message.error('获取上传凭证失败');
            return null;
        }
    };

    // 检查令牌是否过期
    const checkAndRefreshToken = async () => {
        if (!stsToken) {
            return await getSTSToken();
        }

        const expiration = new Date(stsToken.expiration).getTime();
        const now = new Date().getTime();

        if (expiration - now < 5 * 60 * 1000) {
            return await getSTSToken();
        }

        return ossClient;
    };

    // 自定义上传方法
    const customUpload = async ({ file, onSuccess: uploadSuccess, onError }) => {
        try {
            const client = await checkAndRefreshToken();
            if (!client) {
                throw new Error('获取上传凭证失败');
            }

            const fileName = `${folder}/${Date.now()}-${file.name}`;
            const result = await client.put(fileName, file);

            uploadSuccess(result);
            const uploadedUrl = result.url;
            const httpsUrl = getHttpsUrl(uploadedUrl);
            onSuccess(httpsUrl);
            console.log("result.url", result)

            const newFileList = [{
                uid: file.uid,
                name: file.name,
                status: 'done',
                url: httpsUrl
            }];

            setFileList(newFileList);
            onChange?.(newFileList);

        } catch (error) {
            onError(error);
            message.error('上传失败，请重试');
        }
    };

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        onChange?.(newFileList);
    };

    useEffect(() => {
        getSTSToken();
    }, []);

    // 在生成URL时确保使用 https
    const getHttpsUrl = (url) => {
        return url.replace('http://', 'https://');
    };

    return (
        <Upload
            customRequest={customUpload}
            listType={listType}
            fileList={fileList}
            onChange={handleChange}
            accept={accept}
            maxCount={maxCount}
        >
            {fileList.length < maxCount && children}
        </Upload>
    );
};

export default OSSUpload; 