import React, { useRef, useEffect, useState, createRef } from 'react';
import Util from "../Util";
import SignalSocket from "../SignalSocket";
import MsgSerial, {MsgType} from "../MsgSerial";
import {NetSocket, UserInfo, UserStatus} from "../Context";
import {AudioMutedOutlined, AudioOutlined, VideoCameraOutlined} from '@ant-design/icons';
import { Button} from 'antd';
import './Link.scss'

const serial = new MsgSerial();
let globalLivePusher = {};

const Link = () => {

    let [linkChanged, setLinkChanged] = useState(1);

    const [muteVideo, setMuteVideo] = useState(true);
    const [muteAudio, setMuteAudio] = useState(true);

    useEffect(()=>{

        console.log("start push url= ", Util.getPushUrl())

        const container_id = "local_video";
        const url = Util.getPushUrl();
        let livePusher = window.pushRTC(Util.getPushUrl(), container_id);
        globalLivePusher = livePusher;

        livePusher.setAudioQuality('high');

        livePusher.setProperty('enableAudioAEC', true);
        livePusher.setProperty('enableAudioAGC', true);
        livePusher.setProperty('enableAudioANS', true);

        livePusher.setRenderView(container_id);
        // 设置视频质量
        livePusher.setVideoQuality('180p');
        // 设置音频质量
        livePusher.setAudioQuality('high');
        // 自定义设置帧率
        livePusher.setProperty('setVideoFPS', 15);
        livePusher.videoView.muted = true;

        // 采集完摄像头和麦克风之后自动推流
        //
        Promise.all([livePusher.startCamera(), livePusher.startMicrophone()])
            .then(function() {
                livePusher.setVideoMute(muteVideo);
                livePusher.setAudioMute(muteAudio);
                livePusher.startPush(url);
            });

        livePusher.setObserver({
            // 推流连接状态
            onPushStatusUpdate: function(status, message) {
                console.log(status, message);
                window.CallBridge.invokeMethod("status_status", status, message);

                // status 0: disconnected
                if (status == 0) {
                    setTimeout(()=>{
                        livePusher.startPush(url);
                    }, 5000)
                }
            },
            // 推流统计数据
            onStatisticsUpdate: function(data) {
                console.log('video fps is ' + data.video.framesPerSecond);
                window.CallBridge.invokeMethod("status_fps", data.video.framesPerSecond);
            }
        });

        return ()=>{
            globalLivePusher = null;
            livePusher.stopPush();
            livePusher.destroy();
            console.log("pusher cleaned.")
        }
    }, []);

    function onMuteVideo() {
        globalLivePusher.setVideoMute(!muteVideo);
        setMuteVideo(!muteVideo);
    }

    function onMuteAudio() {
        globalLivePusher.setAudioMute(!muteAudio);
        setMuteAudio(!muteAudio);
    }

    return (
        <div className="head-s">
            <div id="local_video">
                <div className="push_mask">
                    <div className="push_mask_bar">
                        <img className={muteVideo ? "vcontrol" : "vcontrol"} src={muteVideo ? "./imgs/camera_close.png" : "./imgs/camera_open.png"} onClick={onMuteVideo}/>
                        <Button className={muteAudio ? "muted" : ""} icon={<AudioOutlined style={{ color: 'rgb(200, 200, 200)', fontSize: '20px' }}/>} type="text" onClick={onMuteAudio}/>
                        <Button className={muteAudio ? "" : "muted"} icon={<AudioMutedOutlined style={{ color: 'rgb(200, 200, 200)', fontSize: '20px' }}/>} type="text" onClick={onMuteAudio}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Link;
