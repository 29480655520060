import React, { useEffect, useState } from 'react';
import './ConsolePage.css'
import BarItem from "./BarItem";
import {Route, Routes, useNavigate } from "react-router-dom";
import HomePage from "./HomePage/HomePage";
import HomePage_Live from "./HomePage_Live/HomePage_Live";
import CloudDiskPage from "./CloudDisk/CloudDiskPage";
import {DataContext, DataProvider} from "./DataProvider";
import { Modal } from 'antd';
import UserProfile from './UserProfile/UserProfile';
import PublicCourse from "./PublicCourse/PublicCourse";
import request from '../Components/net/request';
import SchedulePage from './Schedule/SchedulePage';
import SideBar from './SideBar/SideBar';

const ConsolePage = (props) => {

    const navigate = useNavigate();
    const [showProfile, setShowProfile] = useState(false);
    const [userAvatar, setUserAvatar] = useState('');

    const fetchUserInfo = async () => {
        try {
            const response = await request.get('/api/v1/user/me');
            if (response.data.code === 200 && response.data.data) {
                setUserAvatar(response.data.data.avatar);
            }
        } catch (error) {
            console.error('获取用户信息失败:', error);
        }
    };

    useEffect(() => {
        fetchUserInfo();
    }, []);

    function onTabChanged(id) {
        console.log("-->",id)
        switch(id) {
            case "":  // 主页
                navigate("/");
                break;
            case "cloud_disk":  // 修改为正确的路由名称
                navigate("/cloud_disk");
                break;
            case "schedule":
                navigate("/schedule");
                break;
            default:
                navigate("/");
        }
    }

    const handleProfileClose = () => {
        setShowProfile(false);
        fetchUserInfo();
    };

    return (
        <DataProvider>
            <div className="console_page_container">
                <SideBar 
                    onTabChanged={onTabChanged}
                    userAvatar={userAvatar}
                    onAvatarClick={() => setShowProfile(true)}
                />
                <Routes className='mainDiv'>
                    <Route path="/" element={<HomePage/>} />
                    <Route path="/cloud_disk" element={<CloudDiskPage/>} />
                    <Route path="/schedule" element={<SchedulePage/>} />
                </Routes>
                <Modal
                    title="编辑"
                    open={showProfile}
                    onCancel={handleProfileClose}
                    footer={null}
                    width={400}
                    transitionName=""
                    maskTransitionName=""
                >
                    <UserProfile onClose={handleProfileClose} />
                </Modal>
            </div>
        </DataProvider>
    )
}

export default ConsolePage;
