import React from 'react';
import dayjs from 'dayjs';
import './CourseItem.css';

const CourseItem = ({ course }) => {
    const formatTime = (timeString) => {
        return dayjs(timeString).format('HH:mm');
    };

    // 计算持续时间（分钟）
    const getDuration = () => {
        const start = dayjs(course.begin_time);
        const end = dayjs(course.end_time);
        const minutes = end.diff(start, 'minute');
        return `时长: ${minutes}分钟`;
    };

    return (
        <div className="course-item">
            <div className="course-main-info">
                <div className="course-name">
                    {course.name}
                </div>
                <div className="course-details">
                    <span className="time-info">
                        {formatTime(course.begin_time)}/{formatTime(course.end_time)}
                    </span>
                    <span className="duration">
                        {getDuration()}
                    </span>
                    <span className="teacher-name">
                        教师ID: {course.teacher_id}
                    </span>
                </div>
            </div>
            <div className="course-status">
                <span className={`status-tag ${course.state}`}>
                    {course.state === 'started' ? '进行中' : '未开始'}
                </span>
            </div>
        </div>
    );
};

export default CourseItem; 