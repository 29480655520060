import React, {useContext, useEffect, useState} from 'react';
import './HomePage.scss'
import {InfoCircleOutlined, PlusSquareOutlined, SearchOutlined, UserOutlined} from '@ant-design/icons';
import { Modal, Button, Flex, Tooltip, Input, Dropdown} from 'antd';
import CourseListItem from "./course/CourseListItem";
import { Avatar, List, Skeleton } from 'antd';
import request from "../../Components/net/request";

import {DataContext} from "../DataProvider";
import CreateCourse from "./course/CreateCourse";
import CreatePublicCourse from "./course/CreatePublicCourse";
import CreateLesson from "./lesson/CreateLesson";
import LessonListItem from "./lesson/LessonListItem";
import ImageButton from "../../Components/Common/ImageButton";
import JoinCourse from "./course/JoinCourse";
import Drawer from "antd/es/drawer";
import CourseInfoDrawer from './course/CourseInfoDrawer';

const HomePage = (props) => {

    const [courseTitle, setCourseTitle] = useState("");
    const [joinCourseDialog, setJoinCourseDialog] = useState(false);

    // 创建普通课程
    const {createCourseDialog, setCreateCourseDialog} = useContext(DataContext);
    // 创建讲座课程
    const {createPublicCourseDialog, setCreatePublicCourseDialog} = useContext(DataContext);
    // 创建课节
    const {createLessonDialog, setCreateLessonDialog} = useContext(DataContext);
    // 右侧的课程信息抽屉框
    const {createCourseDrawer, setCreateCourseDrawer} = useContext(DataContext);

    // 视图切换
    const [activeView, setActiveView] = useState('view-main');

    // 左侧的课程数据
    const [courseData, setCourseData] = useState([]);

    // 当前选中的右侧的课节数据
    const [selectedLessonData, setSelectedLessonData] = useState([]);

    // 当前选中的课程信息
    const [selectedCourseData, setSelectedCourseData] = useState({})

    // 当前选中班级的人员信息
    const [selectedClassPeopleData, setSelectedClassPeopleData] = useState([])

    useEffect(()=>{

        // 开始获取班级列表数据
        getClassList();
    }, []);

    function getClassList() {
        request.get("/api/v1/course/list?page=1&page_size=500&order=join_time_desc").then(response => {
            const res = response.data;
            if (res.code == 200 && res.data) {
                setCourseData(res.data.items);
                // 如果有课程数据，自动选中第一个课程
                if (res.data.items.length > 0) {
                    const firstCourse = res.data.items[0];
                    // 调用 classClicked 来触发选中效果和获取课节信息
                    classClicked(firstCourse);
                    setActiveView('view-room'); // 确保切换到课程视图
                }
            }
        })
    }

    function classClicked(data) {
        setCourseTitle(data.name);
        setActiveView('view-room');
        setSelectedCourseData(data);

        // 获取课节信息
        request.get("/api/v1/course/lesson/list?course_uuid=" + data.uuid).then(response => {
            const res = response.data;
            console.log(res)
            if (res.code == 200 && res.data) {
                setSelectedLessonData(res.data);
            }
        })

        // 获取人数信息
        request.get("/api/v1/course/members?page=0&page_size=1000&course_uuid=" + data.uuid).then(response => {
            const res = response.data;
            if (res.code == 200 && res.data && res.data.items) {
                setSelectedClassPeopleData(res.data.items);
            }
        })
    }

    // 创建课程
    function onCreateCourse() {
        setCreateCourseDialog(true)
    }

    function onCreatePublicClicked() {
        setCreatePublicCourseDialog(true);
    }

    function onCreateLessonClicked() {
        setCreateLessonDialog(true);
    }

    const renderView = () => {
        switch (activeView) {
            case 'view-main':
                return (
                    <div className="home_icon_container">
                        <div>
                            <ImageButton src="./imgs/add_class.png" style={{margin: "30px"}}>加入班级</ImageButton>
                            <ImageButton src="./imgs/add_class.png" style={{margin: "30px"}} onClick={onCreateCourse}>创建班级</ImageButton>
                        </div>
                        <div>
                            <ImageButton src="./imgs/add_class.png" style={{margin: "30px"}}>加入公开课</ImageButton>
                            <ImageButton src="./imgs/add_class.png" style={{margin: "30px"}}>创建公开课</ImageButton>
                        </div>
                    </div>
                )
            case 'view-room':
                return (
                    <div className="home_page_right">
                        <div className="home_page_title"><span>{courseTitle}</span><img src="./imgs/dots.svg" onClick={()=>{setCreateCourseDrawer(true)}}/></div>
                        <div className="home_page_kejie">课节</div>
                        <div className="home_page_kejie_list">
                            {
                                selectedLessonData.map((val, idx)=>{
                                    return <LessonListItem peopleData={selectedClassPeopleData} refreshLesson={()=>classClicked(selectedCourseData)} key={idx} data={val} order={idx+1} />
                                })
                            }
                        </div>
                        <div className="home_page_bottom">
                            <Button onClick={onCreateLessonClicked}>创建课节</Button>
                        </div>
                    </div>
                );
            case "view-join-class":
                return (
                   <JoinCourse/>
                );
            default:
                return 'view-main';
        }
    };

    const items = [
        {
            label: <span className='join-class-button'
                onClick={()=>{
                    console.log("加入班级")
                    setJoinCourseDialog(true);
                }}
            >加入班级</span>,
            key: '0',
        },
        {
            type: 'divider',
        },
        {
            label: <span>加入公开课</span>,
            key: '1',
        }
    ];

    return (
        <div className="home_page_container">
            <div className="home_page_left">
                <div className="search-container">
                    <Input
                        placeholder="搜索"
                        prefix={
                            <SearchOutlined
                                style={{
                                    color: 'rgba(0,0,0,.25)',
                                }}
                            />
                        }
                    />

                    <Dropdown
                        menu={{
                            items,
                        }}
                        trigger={['click']}
                    >

                        <PlusSquareOutlined className="add-button"
                                            style={{
                                                color: '#E9E9E9',
                                            }}

                                            onClick={(e) => e.preventDefault()}
                        />

                    </Dropdown>

                </div>
                <div className="create_bar_container">
                    <Button icon={<img src="./imgs/create_public.svg" style={{ width: '1em', height: '1em' }} />}
                            onClick={onCreatePublicClicked} >创建讲座</Button>
                    <Button icon={<img src="./imgs/create_class.svg" style={{ width: '1em', height: '1em' }} />}
                            onClick={onCreateCourse}>创建班级</Button>
                </div>

                <div style={{height: "1px", width: "100%", background: "lightgray"}}></div>

                <div className="home_page_class_list">
                    {courseData.map((val, idx) => (
                        <CourseListItem 
                            key={idx} 
                            data={val} 
                            onClick={classClicked}
                            isSelected={val.uuid === selectedCourseData.uuid}
                        />
                    ))}
                </div>

            </div>
            {renderView()}
            {createCourseDialog && <CreateCourse getClassList={getClassList}/>}
            {createPublicCourseDialog && <CreatePublicCourse/>}
            {createLessonDialog && <CreateLesson onClick={()=>classClicked(selectedCourseData)} courseData={selectedCourseData}/>}
            {createCourseDrawer && (
                <CourseInfoDrawer 
                    open={true} 
                    onClose={() => setCreateCourseDrawer(false)} 
                    courseData={selectedCourseData}
                    onDeleted={getClassList}
                />
            )}
            {joinCourseDialog && (
                <Modal
                    open={joinCourseDialog}
                    title="加入班级"
                    onCancel={() => setJoinCourseDialog(false)}
                    footer={null}
                    transitionName=""
                    maskTransitionName=""
                    mask={false}
                >
                    <div className="dialog_line"></div>
                    <JoinCourse onClose={() => setJoinCourseDialog(false)} />
                </Modal>
            )}
        </div>
    )
}

export default HomePage;
