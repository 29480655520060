import React, { createContext, useState } from 'react';

const DataContext_Teacher = createContext();

function DataProvider_Teacher({ children }) {
    const [rtcList, setRtcList] = useState([]);

    // rtc连麦列表信息更新通知，可以订阅这个消息
    const [rtcListChanged, setRtcListChanged] = useState(1);

    return (
        <DataContext_Teacher.Provider value={{
            rtcList, setRtcList,
            rtcListChanged, setRtcListChanged
        }}>
            {children}
        </DataContext_Teacher.Provider>
    );
}

export { DataContext_Teacher, DataProvider_Teacher };
