import React, {useRef, useEffect, useState, createRef, useContext} from 'react';
import './LivePage_Teacher.css'
import { WechatWorkOutlined, UsergroupAddOutlined, FullscreenOutlined } from '@ant-design/icons';
import ChatRoom from "../ChatRoom";
import Util from "../Util";
import {NetSocket, RoomInfo, UserInfo} from "../Context";
import LineWidget from "../../Components/Common/LineWidget";
import MsgSerial, {MsgType} from "../MsgSerial";
import SignalSocket from "../SignalSocket";
import {DataContext_Student} from "../student/DataProvider_Student";

// 创建一个 ResizeObserver 实例
const resizeObserver = new ResizeObserver(entries => {
    for (let entry of entries) {
        // update size
        entry.target.updateSize();
    }
});

const serial = new MsgSerial();

const LivePage_Teacher = (props, ref) => {

    const [size, setSize] = useState({});
    const [chatCount, setChatCount] = useState(1);

    const {rtcList, setRtcList} = useContext(DataContext_Student);
    const {rtcListChanged, setRtcListChanged} = useContext(DataContext_Student);

    useEffect(()=>{

        const video = document.getElementById('head_video');

        // 访问用户媒体设备的媒体流
        navigator.mediaDevices.getUserMedia({ video: { deviceId: RoomInfo.Vid } })
            .then((stream) => {
                // 将媒体流设置为 video 元素的源
                video.srcObject = stream;
                console.log('访问摄像头OK');
                video.play();
            })
            .catch((err) => {
                console.error('访问摄像头Failed:', err);
            });

        // 监听浏览器窗口的 resize 事件
        //window.addEventListener('resize', handleResize);

        // build signal socket
        const signalSo = new SignalSocket(Util.getSignalUrl(), "signal");
        signalSo.addOpenListener(()=>{
            signalSo.sendMessage(serial.serial(MsgType.Type_Info, Util.getUserId(), Util.getUserName(), 'teacher join room'));
        });

        NetSocket.SignalSo = signalSo;

        signalSo.addMessageListener((msg)=>{

            if (msg.id ==Util.getUserId()) return;

            window.CallBridge.invokeMethod("sig_msg", JSON.stringify(msg));

            if (msg.t == MsgType.Type_Rtc) {
                setRtcList(msg.data);

                let val = rtcListChanged;
                setRtcListChanged(val++);
            }
        });

        return ()=>{
            // 卸载浏览器窗口的 resize 事件
            //window.removeEventListener('resize', handleResize);
        }
    }, []);

    return (
        <div className="right_container">
            <div className="video-head-container">
                <video className="head_video" id="head_video"></video>
            </div>
            <div className="tab_content_t">
                <div className="chat-room-title room-title-teacher">
                    <WechatWorkOutlined/>
                    <span className="chat-room-title-text">
                        聊天区（在线{chatCount}人）
                    </span>
                </div>
                <LineWidget width="100%" height="1px" color="rgb(82, 82, 82)"/>
                <ChatRoom countFn={setChatCount} forTeacher={true}/>
            </div>
        </div>
    )
}

export default LivePage_Teacher;

