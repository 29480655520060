import React, { useState } from 'react';
import { Calendar, Button, message } from 'antd';
import locale from 'antd/es/calendar/locale/zh_CN';
import dayjs from 'dayjs';
import request from '../../Components/net/request';
import CourseItem from './CourseItem';  // 导入新组件
import './SchedulePage.css';

const SchedulePage = () => {
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [courseList, setCourseList] = useState([]);

    const fetchCourseList = async (date) => {
        try {
            // 计算当天0点和23:59:59的秒级时间戳
            const startTime = date.hour(0).minute(0).second(0).unix();
            const endTime = date.hour(23).minute(59).second(59).unix();

            const params = {
                min_began_time: startTime,
                max_began_time: endTime
            };

            const response = await request.get('/api/v1/course/timetable', { params });
            if (response.data.code === 200) {
                setCourseList(response.data.data.timetable || []);  // 修改这里以匹配返回数据结构
            } else {
                message.error('获取课程列表失败');
            }
        } catch (error) {
            console.error('获取课程列表失败:', error);
            message.error('获取课程列表失败');
        }
    };

    const onDateSelect = (date) => {
        setSelectedDate(date);
        fetchCourseList(date);
    };

    const goToToday = () => {
        const today = dayjs();
        setSelectedDate(today);
        fetchCourseList(today);
    };

    // 组件加载时获取当天的课程列表
    React.useEffect(() => {
        fetchCourseList(selectedDate);
    }, []);

    return (
        <div className="schedule-page">
            <div className="schedule-container">
                <div className="schedule-calendar">
                    <Calendar 
                        fullscreen={false}
                        locale={locale}
                        onSelect={onDateSelect}
                        value={selectedDate}
                    />
                    <div className="calendar-divider"></div>
                    <div className="calendar-footer">
                        <Button type="primary" onClick={goToToday}>
                            今天
                        </Button>
                    </div>
                </div>
                <div className="schedule-content">
                    <div className="date-header">
                        {selectedDate.format('YYYY年MM月DD日')} 星期{['日', '一', '二', '三', '四', '五', '六'][selectedDate.day()]}
                    </div>
                    <div className="schedule-list">
                        {courseList.length > 0 ? (
                            courseList.map(course => (
                                <CourseItem 
                                    key={course.id} 
                                    course={course}
                                />
                            ))
                        ) : (
                            <div className="no-class">
                                暂无课程
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SchedulePage; 