// src/ChatBox.js

import React, { useState, useEffect, useRef } from 'react';
import {List, Input, Button, Layout, Tabs, Flex} from 'antd';
//import 'antd/dist/antd.css';
import './ChatRoom.scss';
import Message from "./Message";
import { SendOutlined } from "@ant-design/icons";
import SignalSocket from "./SignalSocket";
import ChatSocket from "./ChatSocket";
import Util from "./Util";
import {NetSocket, UserInfo} from "./Context";
import MsgSerial, {MsgType} from "./MsgSerial";
import {fireChangeForInputTimeIfValid} from "@testing-library/user-event/dist/keyboard/shared";
import LineWidget from "../Components/Common/LineWidget";

const {  Footer, Content } = Layout;

const { TextArea } = Input;

const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#4096ff',
    height: '64px'
};
const layoutStyle = {
    borderRadius: 8,
    overflow: 'auto',
    height: '100%',
};

const contentStyle = {
    textAlign: 'center',
    minHeight: 120,
    color: '#fff',
    backgroundColor: '#0958d9',
};

let messageCache = [];
const serial = new MsgSerial;
let sendMessageCache = '';

const ChatRoom = (props) => {
    let [updateMsg, setUpdateMsg] = useState(1);

    useEffect(()=>{
        // build chat socket
        const chatSo = new ChatSocket(Util.getChatUrl(), "chat");
        console.log("chat: ", Util.getChatUrl());
        chatSo.addOpenListener(()=>{
            // chatSo.sendMessage(serial.serial(MsgType.Type_Info, UserInfo.ID, UserInfo.NAME, 'join room'));
        });
        NetSocket.ChatRoomSo = chatSo;

        chatSo.addMessageListener(onMessage);

        return ()=>{
            chatSo.close();
        }
    }, []);

    function onMessage(msg) {
        const type = msg.t;
        if (type == MsgType.Type_Chat) {
            if (msg.si == Util.getUserId()) {
                console.log("self msg, ignored.");
                return;
            }

            onChatMessage(msg);
        } else if (type == MsgType.Type_ChatCount) {
            props.countFn(msg.c);
        }
    }

    const sendMessage = () => {
        if(sendMessageCache.trim()) {
            const newMessage = { text: sendMessageCache, timestamp: Date.now(), uuid: Util.guid(), name: Util.getUserName(), head: "imgs/head.png" };

            NetSocket.ChatRoomSo.sendMessage(serial.serial(MsgType.Type_Chat, Util.getUserId(), Util.getUserName(), sendMessageCache))

            messageCache.push(newMessage);
            updateMsg += 1;
            setUpdateMsg(updateMsg);
        }
    }

    function onChatMessage(msg) {
        const newMessage = { text: msg.c, timestamp: Date.now(), uuid: Util.guid(), name: msg.sn, head: "imgs/head.png" };
        messageCache.push(newMessage);

        updateMsg += 1;
        setUpdateMsg(updateMsg);
    }

    return (
        <div className="chat-room-container">
            <div className='room_main' r={updateMsg}>
                {messageCache.map((msg, index) => (
                    <Message key={msg.uuid} message={msg} />
                ))}
            </div>
            <div className="input-container">
                <Input
                    placeholder="在此输入聊天"
                    onPressEnter={sendMessage}
                    onChange={(e)=>{  sendMessageCache = e.target.value}}
                    className={props.forTeacher ? "chat-input chat-input-teacher" : "chat-input"}
                />
                <LineWidget width="10px" height="1px"/>
                <SendOutlined style={{ color: 'rgb(200, 200, 200)', fontSize: '20px' }} onClick={sendMessage}/>
            </div>
        </div>
    );
};

export default ChatRoom;
