import React, {useEffect, useRef, useState} from 'react';
import LineWidget from "../../../Components/Common/LineWidget";
import { Input, Button, Avatar } from 'antd';
import { UserOutlined, CloseCircleFilled } from '@ant-design/icons';
import "./JoinCourse.css"
import "../../../Components/Common/Common.css";
import Space from "../../../Components/Common/Space";
import TextWithSpace from "../../../Components/Common/TextWithSpace";
import request from "../../../Components/net/request";

const JoinCourse = ({ onClose }) => {

    const [classId, setClassId] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [classData, setClassData] = useState({});
    // 视图切换
    const [activeView, setActiveView] = useState('view-empty');

    const handleInputChange = (e) => {
        const value = e.target.value;
        // 只允许输入数字
        if (value === '' || /^\d+$/.test(value)) {
            setInputValue(value);
            setClassId(value); // 不需要转换为数字，在使用时再转换
        }
    };

    const handleClear = () => {
        setInputValue('');
        setClassId(0);
    };

    useEffect(()=>{
        return ()=>{

        }
    }, []);

    const renderView = () => {
        switch (activeView) {
            case 'view-empty':
                return (
                    <div className="empty-container">
                        没有找到搜索条件的班级
                    </div>
                )
            case 'view-data':
                return (
                    <div className="class-info">
                        <div className="class-info-header">
                            <div className="class-info-title">{classData.name || "初三数学班"}</div>
                            <div className="class-info-code">班级号：{classData.unique_code || "267731943"}</div>
                        </div>
                        <div className="class-members">
                            <h4>班级成员 (4)</h4>
                            {[1,2,3,4,5,6,7,8,9,10].map((_, index) => (
                                <div key={index} className="member-item">
                                    <Avatar icon={<UserOutlined />} className="member-avatar" />
                                    <span>这里是姓名</span>
                                </div>
                            ))}
                        </div>
                        <div className="join-button-container">
                            <button className="join-button" onClick={onJoinClass}>
                                加入班级
                            </button>
                        </div>
                    </div>
                )
            default:
                return '';
        }
    };

    function onJoinClass() {
        request.post("/api/v1/course/member/join", {
            "course_uuid": classData.uuid,
        }).then(response => {
            console.log('Response data:', response.data);
            if (onClose) {
                onClose();
            }
        })
    }

    function onSearch() {
        if (classId.toString().length == 0) {
            setActiveView("view-empty");
            return;
        }

        // 开始获取班级列表数据
        const uri = `/api/v1/course/search?unique_code=${classId}`;
        request.get(uri).then(response => {
            const res = response.data;
            console.log(res)
            if (res.code == 200 && res.data && res.data.items.length > 0) {
                //setClassData(res.data.items);
                setClassData(res.data.items[0]);
                setActiveView("view-data")
            } else {
                setActiveView("view-empty");
            }
        })
    }

    return (
        <div className="join-class-container">
            <div className="join-class-search-container">
                <Input
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="请输入班级号"
                    suffix={
                        <CloseCircleFilled
                            style={{
                                color: '#999',
                                cursor: 'pointer',
                                visibility: inputValue ? 'visible' : 'hidden'
                            }}
                            onClick={handleClear}
                        />
                    }
                />
                <Space width="10px"/>
                <Button onClick={onSearch} type="primary"><TextWithSpace lspace={4} rspace={4}>搜索</TextWithSpace></Button>
            </div>
            <Space height="10px"/>
            <div>
                {renderView()}
            </div>
        </div>
    )
}

export default JoinCourse;
