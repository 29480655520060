import React, { useState, useRef, useEffect } from 'react';
import {Modal, Button, message, Select, Spin} from 'antd';

import { Layout } from 'antd';
import CustomProgressBar from "./CustomProgressBar";

import "./CameraSelection.css"
import Progress from "antd/es/progress";
import ImageButton from "../Common/ImageButton";
import LessonListItem from "../../Console/HomePage/lesson/LessonListItem";

const { Sider, Content } = Layout;

const { Option } = Select;

let audioContext = null;
let analyser = null;
let timerID = null;

const CameraSelection = () => {
    const [visible, setVisible] = useState(true);
    const [selectedCamera, setSelectedCamera] = useState(null);
    const [selectedMicrophone, setSelectedMicrophone] = useState(null);
    const [cameraDevices, setCameraDevices] = useState([]);
    const [microphoneDevices, setMicrophoneDevices] = useState([]);
    const [videoStream, setVideoStream] = useState(null);
    const [audioStream, setAudioStream] = useState(null);
    const [recording, setRecording] = useState(false);
    const [audioChunks, setAudioChunks] = useState([]);
    const [audioBlob, setAudioBlob] = useState(null);
    const [audioUrl, setAudioUrl] = useState(null);
    const audioRef = useRef(null);
    const videoRef = useRef(null);
    const [currentVolume, setCurrentVolume] = useState(0); // 当前音量

    const [activeView, setActiveView] = useState('view-empty');

    const mediaRecorderRef = useRef(null);

    // 获取摄像头和麦克风设备列表
    useEffect(() => {
        document.title = "设备选择";
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const cameras = devices.filter(device => device.kind === 'videoinput');
                const microphones = devices.filter((device) =>
                {
                    console.log(device)
                    return device.kind === 'audioinput' && device.deviceId !== "default" && device.deviceId != "communications";
                });
                setCameraDevices(cameras);
                setMicrophoneDevices(microphones);
                setActiveView('view-main');
            })
            .catch(error => {
                console.error('Error enumerating devices:', error);
            });
    }, []);

    const handleOk = () => {

        window.CallBridge.invokeMethod("device_video", selectedCamera);
        window.CallBridge.invokeMethod("device_audio", selectedMicrophone);
        window.CallBridge.invokeMethod("Ok");
    }
    // 关闭模态框
    const handleCancel = () => {

        window.CallBridge.invokeMethod("Cancel");

        setVisible(false);
        // 停止音频和视频播放、录制
        if (audioStream) {
            audioStream.getTracks().forEach(track => track.stop());
            setAudioStream(null);
        }
        if (videoStream) {
            videoStream.getTracks().forEach(track => track.stop());
            setVideoStream(null);
        }
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
            mediaRecorderRef.current.stop();
            setRecording(false);
        }

        if (timerID) {
            clearTimeout(timerID);
            audioContext = null;
            analyser = null;
        }

        if (audioContext) {
            audioContext.close();
        }
    };

    // 选择摄像头
    const handleCameraChange = async deviceId => {
        setSelectedCamera(deviceId);
        if (videoStream) {
            videoStream.getTracks().forEach(track => track.stop());
        }
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId } });
            setVideoStream(stream);
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            }
        } catch (error) {
            console.error('Error accessing camera:', error);
            message.error('无法访问摄像头');
        }
    };

    // 选择麦克风
    const handleMicrophoneChange = async deviceId => {
        setSelectedMicrophone(deviceId);
        navigator.mediaDevices.getUserMedia({ audio: { deviceId: deviceId } })
            .then(stream => {
                setAudioStream(stream);
                monitorAudioVolume(stream);
            })
            .catch(error => {
                console.error('getUserMedia error:', error);
                message.error('无法访问麦克风');
            });
    };

    // 开始录制音频
    const startRecording = () => {
        if (!selectedMicrophone) {
            message.error('请先选择一个麦克风设备');
            return;
        }

        const mediaRecorder = new MediaRecorder(audioStream);
        mediaRecorderRef.current = mediaRecorder;

        mediaRecorder.ondataavailable = event => {
            if (event.data.size > 0) {
                setAudioChunks(prevChunks => [...prevChunks, event.data]);
            }
        };

        mediaRecorder.onstop = () => {
            const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
            setAudioBlob(audioBlob);
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioUrl);
            setRecording(false);
        };

        mediaRecorder.start();
        setRecording(true);
    };

    // 停止录制音频
    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
            mediaRecorderRef.current.stop();
        }
    };

    // 播放录制的音频
    const playRecordedAudio = () => {
        if (audioUrl) {
            audioRef.current.src = audioUrl;
            audioRef.current.play();
        }
    };

    // 监听音频流的音量变化
    const monitorAudioVolume = (stream) => {

        if (timerID) {
            clearTimeout(timerID);
            audioContext = null;
            analyser = null;
        }

        if (audioContext) {
            audioContext.close();
        }

        audioContext = new AudioContext();
        analyser = audioContext.createAnalyser();
        analyser.fftSize = 512;

        const microphone = audioContext.createMediaStreamSource(stream);
        microphone.connect(analyser);


        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const updateVolume = () => {
            analyser.getByteFrequencyData(dataArray);
            const average = dataArray.reduce((acc, value) => acc + value, 0) / bufferLength;

            const percentage = (average / 255) * 100;

            console.log(percentage,percentage.toFixed(2), Math.round(percentage.toFixed(2)))

            setCurrentVolume(Math.round(percentage.toFixed(2)));
        };

        timerID = setInterval(updateVolume, 50);
    };

    useEffect(() => {

    }, []);

    function renderView() {
        switch (activeView) {
            case 'view-main':
                return (
                    <div>
                        <div className="section_av">
                            <div className="section_left">
                                <Select
                                    style={{  margin: '10px' }}
                                    placeholder="请选择摄像头"
                                    onChange={handleCameraChange}
                                    defaultValue={0}
                                >
                                    {cameraDevices.map(device => (
                                        <Option key={device.deviceId} value={device.deviceId}>{device.label}</Option>
                                    ))}
                                    <Option key={0} value={0}>禁用</Option>
                                </Select>
                                <Select
                                    style={{ margin: '10px' }}
                                    placeholder="请选择麦克风"
                                    onChange={handleMicrophoneChange}
                                    defaultValue={0}
                                >
                                    {microphoneDevices.map(device => (
                                        <Option key={device.deviceId} value={device.deviceId}>{device.label}</Option>
                                    ))}
                                    <Option key={0} value={0}>禁用</Option>
                                </Select>

                                <div style={{ marginTop: '0.5rem' }}>
                                    <div style={{width: '80px', display: "inline-block"}}>当前音量: </div>
                                    <CustomProgressBar percent={currentVolume}></CustomProgressBar>
                                </div>
                            </div>`
                            <div className="section_right">
                                <div style={{margin: '10px', width: "240px", height: "180px", backgroundColor: "black"}}>
                                    <video ref={videoRef} autoPlay playsInline style={{width: '100%', height: "100%"}}/>
                                </div>
                            </div>
                        </div>
                        <div style={{height: "29px"}}/>
                        <div className="tool_btn">
                            <Button className="tbutton" key="back" onClick={handleOk}>
                                进入直播间
                            </Button>
                            {/*<Button className="tbutton" key="back1" onClick={handleCancel}>*/}
                            {/*    取消*/}
                            {/*</Button>*/}
                        </div>
                    </div>
                );
            case 'view-empty':
                return (
                    <div className="device-empty-container">
                        <Spin size="large" />
                    </div>
                );
            default:
                return 'view-main';
        }
    }


    return (
        <div className="main_container">
            {renderView()}
        </div>
    );
};

export default CameraSelection;
