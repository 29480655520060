import React, { useState } from 'react';
import { Modal, Input, Button, message, Space } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import './ImportStudentsModal.scss';

const { TextArea } = Input;

const ImportStudentsModal = ({
    open,
    onClose,
    courseUuid
}) => {
    const [phoneNumbers, setPhoneNumbers] = useState('');

    const handlePasteFromClipboard = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setPhoneNumbers(text);
        } catch (err) {
            message.error('无法访问剪贴板，请手动粘贴');
        }
    };

    const handleImport = async () => {
        // 处理输入的手机号
        const numbers = phoneNumbers.split('\n')
            .map(num => num.trim())
            .filter(num => num); // 过滤空行

        if (numbers.length === 0) {
            message.warning('请输入手机号');
            return;
        }

        // 验证手机号格式
        const invalidNumbers = numbers.filter(num => !/^1[3-9]\d{9}$/.test(num));
        if (invalidNumbers.length > 0) {
            message.error(`存在无效的手机号：${invalidNumbers.join(', ')}`);
            return;
        }

        try {
            // TODO: 调用导入接口
            message.success('导入成功');
            onClose();
        } catch (error) {
            message.error('导入失败，请稍后重试');
        }
    };

    return (
        <Modal
            title="导入学生"
            open={open}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    取消
                </Button>,
                <Button key="submit" type="primary" onClick={handleImport}>
                    确定导入
                </Button>
            ]}
        >
            <div className="import-students-container">
                <div className="import-tip">请输入或粘贴学生手机号，每行一个</div>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Button 
                        icon={<CopyOutlined />} 
                        onClick={handlePasteFromClipboard}
                    >
                        从剪贴板粘贴
                    </Button>
                    <TextArea
                        value={phoneNumbers}
                        onChange={e => setPhoneNumbers(e.target.value)}
                        placeholder="例如：
13812345678
13987654321"
                        rows={10}
                        className="phone-input"
                    />
                </Space>
            </div>
        </Modal>
    );
};

export default ImportStudentsModal; 