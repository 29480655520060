import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import "./LessonListItem.scss"
import { LinkOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import BindLessonVideo from './BindLessonVideo';

const LessonListItem = (props) => {

    let {peopleData, data, refreshLesson} = props;
    let stateText = checkTimeStatus(props.data.begin_time, props.data.end_time);
    const [showBindModal, setShowBindModal] = useState(false);

    useEffect(()=>{
        return ()=>{

        }
    }, []);

    function getTimeDifference(startTime, endTime) {
        // 将时间字符串转换为 moment 对象
        const start = moment(startTime);
        const end = moment(endTime);

        // 计算时间差
        const duration = moment.duration(end.diff(start));

        // 获取小时和分钟
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();

        if (hours == 0) {
            return `${minutes}分钟`;
        }

        return `${hours}小时${minutes}分钟`;
    }

    function formatDate(date) {
        const daysOfWeek = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
        const dayOfWeek = daysOfWeek[date.day()];

        return date.format(`YYYY/MM/DD HH:mm ${dayOfWeek}`);
    }

    function checkTimeStatus(timeA, timeB) {
        const now = new Date().getTime();
        const startTime = new Date(timeA).getTime();
        const endTime = new Date(timeB).getTime();

        // 计算时间差（以毫秒为单位）
        const diff = now - startTime;
        const diffInMinutes = diff / (1000 * 60); // 将时间差转换为分钟

        const diff2= now - endTime;
        const diffInMinutes2 = diff2 / (1000 * 60); // 将时间差转换为分钟

        if ((startTime - now) > 30*60*1000 ) {
            return "备课";
        }

        if ((startTime - now) <= 30*60*1000 && (now - endTime) <= 30*60*1000) {
            return "上课";
        }

        if ((now - endTime) > 30*60*1000) {
            return "看回放";
        }
    }

    async  function enterClass() {

        if (stateText === "上课" || stateText === "备课") {
            window.CallBridge.invokeMethod("enter_class", JSON.stringify(props.data));
        }

        if (stateText === "看回放" && props.data.replay_url.length > 0) {
            window.CallBridge.invokeMethod("enter_vod", JSON.stringify(props.data));
        }
    }

    const handleBind = (e) => {
        e.stopPropagation(); // 防止触发父元素的点击事件
        setShowBindModal(true);
    };

    return (
        <div className="course_item_main_container">
            <div className="order">{props.order}</div>
            <div className="info">
                <div className="name">{props.data.name}</div>
                <div className="text">学生人数：{peopleData.length}人</div>
                <div className="text">开始时间：{formatDate(moment(props.data.begin_time))} &nbsp;&nbsp; ({getTimeDifference(props.data.begin_time, props.data.end_time)})</div>
            </div>
            <div style={{flexGrow: 1}}></div>
            <div className="action-buttons">
                <div className="bind-button" onClick={handleBind}>
                    <LinkOutlined />
                </div>
                <div className={`state ${stateText === "课程结束" ? "old" : ""} ${stateText === "看回放" && !data.replay_url ? "disabled" : ""}`} onClick={enterClass}>
                    <div>{stateText}</div>
                </div>
            </div>
            <Modal
                title="回放视频绑定"
                open={showBindModal}
                onCancel={() => setShowBindModal(false)}
                footer={null}
                width={1200}
                height={800}
                // bodyStyle={{ padding: 0 }}
                // style={{ top: 20 }}
                transitionName=""
                maskTransitionName=""
            >
                <BindLessonVideo onClose={() => setShowBindModal(false)} courseData={data} refreshLesson={refreshLesson} />
            </Modal>
        </div>
    )
}

export default LessonListItem;
