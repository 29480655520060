import React, { useEffect, useRef, useState, useCallback } from 'react';
import { PlayCircleOutlined, PauseCircleOutlined, ForwardOutlined } from '@ant-design/icons';
import { Slider } from 'antd';
import "./VideoReplay.scss"

const VideoReplay = ({ url }) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showControls, setShowControls] = useState(false);
    const [controlsTimeout, setControlsTimeout] = useState(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [playbackRate, setPlaybackRate] = useState(1);

    const togglePlay = useCallback(() => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
        }
    }, [isPlaying]);

    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;

        const handleKeyPress = (e) => {
            if (e.code === 'Space') {
                e.preventDefault();
                togglePlay();
            }
        };

        const handlePlay = () => setIsPlaying(true);
        const handlePause = () => setIsPlaying(false);
        const handleTimeUpdate = () => setCurrentTime(video.currentTime);
        const handleDurationChange = () => setDuration(video.duration);

        document.addEventListener('keydown', handleKeyPress);
        video.addEventListener('play', handlePlay);
        video.addEventListener('pause', handlePause);
        video.addEventListener('timeupdate', handleTimeUpdate);
        video.addEventListener('loadedmetadata', handleDurationChange);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            video.removeEventListener('play', handlePlay);
            video.removeEventListener('pause', handlePause);
            video.removeEventListener('timeupdate', handleTimeUpdate);
            video.removeEventListener('loadedmetadata', handleDurationChange);
            if (controlsTimeout) {
                clearTimeout(controlsTimeout);
            }
        };
    }, [controlsTimeout, togglePlay]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const changePlaybackRate = () => {
        const rates = [1, 1.25, 1.5, 2];
        const currentIndex = rates.indexOf(playbackRate);
        const nextRate = rates[(currentIndex + 1) % rates.length];
        setPlaybackRate(nextRate);
        if (videoRef.current) {
            videoRef.current.playbackRate = nextRate;
        }
    };

    const handleSliderChange = (value) => {
        const newTime = (value / 100) * duration;
        videoRef.current.currentTime = newTime;
        setCurrentTime(newTime);
    };

    return (
        <div className="video-replay-container">
            <div className="video-wrapper"
                onMouseMove={() => {
                    setShowControls(true);
                    if (controlsTimeout) {
                        clearTimeout(controlsTimeout);
                    }
                    const timeout = setTimeout(() => {
                        setShowControls(false);
                    }, 3000);
                    setControlsTimeout(timeout);
                }}
            >
                <video
                    ref={videoRef}
                    className="video-player"
                    src={url}
                    tabIndex="0"
                />
                {!isPlaying && url && (
                    <div className="pause-overlay" onClick={togglePlay}>
                        <PlayCircleOutlined className="pause-icon" />
                    </div>
                )}
                <div className={`controls ${showControls ? 'show' : ''}`}>
                    <Slider
                        className="progress-slider"
                        value={(currentTime / duration) * 100}
                        onChange={handleSliderChange}
                        tooltip={{ formatter: null }}
                    />
                    <div className="controls-content">
                        <button className="control-button" onClick={togglePlay}>
                            {isPlaying ? 
                                <PauseCircleOutlined style={{ fontSize: '48px' }} /> : 
                                <PlayCircleOutlined style={{ fontSize: '48px' }} />
                            }
                        </button>
                        <div className="time-display">
                            {formatTime(currentTime)} / {formatTime(duration)}
                        </div>
                        <button className="speed-button" onClick={changePlaybackRate}>
                            {playbackRate}x
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoReplay;
