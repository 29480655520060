import React from 'react';
import { MessageOutlined } from '@ant-design/icons';
import "./CourseListItem.scss";

const CourseListItem = ({ data, onClick, isSelected }) => {
    return (
        <div 
            className={`class_main_container ${isSelected ? 'active' : ''}`}
            onClick={() => onClick(data)}
        >
            <div className="class_container">
                <img 
                    src={data.cover_image}
                    className="class_head_pic" 
                    alt={data.name}
                />
                <span className="class-name">{data.name}</span>
                <span className="spacer"></span>
            </div>
        </div>
    );
};

export default CourseListItem;
