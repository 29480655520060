import React, {useContext, useEffect, useState} from 'react';
import './RtcPlayPage_Teacher.css'
import {DataContext_Teacher} from "./DataProvider_Teacher";
import {DataContext_Student} from "../student/DataProvider_Student";
import {MsgType} from "../MsgSerial";

function RtcPlayItem({data}) {

    useEffect(()=>{

        let stuId = `${data.id}`;
        let elementId = 'id-' + stuId;
        let url = 'webrtc://play.xiaoyunduoyuan.com/live/s_' + stuId;

        console.log("play uuid, ", document.getElementById(elementId))
        let player = window.loadVideo(url, elementId);

        return ()=>{
            player.dispose();
        }

    }, [])


    return (
        <div className={`video-container-t`}>
            <video className="video_rtc" id={`id-${data.id}`} preload="auto" playsInline webkit-playsinline="true"/>
        </div>
    );
}
let val = 1;
function RtcPlayPage_Teacher() {

    //const {rtcData, setRtcData} = useContext(DataContext_Teacher);
    //const {rtcListChanged, setRtcListChanged} = useContext(DataContext);

    const {rtcList, setRtcList} = useContext(DataContext_Teacher);
    const {rtcListChanged, setRtcListChanged} = useContext(DataContext_Teacher);


    function onSigMessage(raw_msg) {
        console.log("onSigMessage", raw_msg, rtcList)

        let msg = JSON.parse(raw_msg);
        if (msg.t == MsgType.Type_Rtc) {
            console.log(msg.data)

            setRtcList(msg.data);

            let val = rtcListChanged;
            setRtcListChanged(val++);
        }
    }

    useEffect(()=>{

        // add Event
        window.CallBridge.addEventListener("sig_msg", onSigMessage);

        // remove event
        return ()=>{window.CallBridge.removeEventListener("sig_msg", onSigMessage)};
    }, [])

    function createRtc() {
        let rtcElements = [];
        rtcListChanged && rtcList.map((msg, index) => {
                if (msg.id !== window.user_info.user.id) {
                    rtcElements.push(<RtcPlayItem key={msg.id} data={msg}/>)
                }
        }
        )

        return rtcElements;

        // rtcData.map((val, index) => {
        //     if (val.id !== window.user_info.user.id) {
        //         rtcElements.push(<RtcPlayItem key={val.id} data={val}/>);
        //     }
        // })
        //
        // return rtcElements;
    }

    return (
        <div className="top-rtc-container">
            {createRtc()}
        </div>
    );
}

export default RtcPlayPage_Teacher;
