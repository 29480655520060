import React, {useRef, useEffect, useState, createRef, useContext} from 'react';
import './LivePage_Student.css'
import { WechatWorkOutlined, UsergroupAddOutlined, FullscreenOutlined } from '@ant-design/icons';
import ChatRoom from "../ChatRoom";
import Util from "../Util";
import LineWidget from "../../Components/Common/LineWidget";
import RtcPlayPage_Student from "./RtcPlayPage_Student";
import Switch from "antd/es/switch";
import Link from "./Link";
import SignalSocket from "../SignalSocket";
import MsgSerial, {MsgType} from "../MsgSerial";
import {NetSocket} from "../Context";
import {DataProvider} from "../../Console/DataProvider";
import {DataProvider_Student} from "./DataProvider_Student";
import {DataContext_Student} from "./DataProvider_Student";

const videoContainerStyle = {
    width: '600px',
    height: "300px",
    position: "absolute",
    clipPath: 'polygon(0 0, 85.48019093078759% 0, 85.48019093078759% 100%, 0 100%)',
}

// 创建一个 ResizeObserver 实例
const resizeObserver = new ResizeObserver(entries => {
    for (let entry of entries) {
        const width = entry.contentRect.width;
        const height = entry.contentRect.height;
        console.log(`Width: ${width}, Height: ${height}`);

        let video_container = entry.target.video_target;

        if (width/height <= 1.777227722772277) {
            let extendWidth = (1436+240)/1436 * width;
            let factor = extendWidth / 1676;

            let scaledHeight = 808 * factor;
            // 设置图像的宽度和高度

            video_container.style.width = `${extendWidth}px`;
            video_container.style.height = `${scaledHeight}px`;
            // 中心定位图像
            video_container.style.left = '0px';

            //console.log("left2" , left)

            let top = height / 2 - scaledHeight / 2;
            video_container.style.top = `${top}px`;

        } else {
            let factor = height / 808;
            let scaledWidth = 1676 * factor;
            let sw = scaledWidth * 0.8568019093078759;

            video_container.style.width = `${scaledWidth}px`;
            video_container.style.height = `${height}px`;
            // 中心定位图像

            let left = width / 2 - sw / 2;
            video_container.style.left = `${left}px`;

            console.log("left2" , left)

            video_container.style.top = '0px';
        }

        // update size
        entry.target.updateSize();
    }
});

const serial = new MsgSerial();

const LivePage_Student = (props, ref) => {

    const [size, setSize] = useState({});
    const [chatCount, setChatCount] = useState(1);
    const {rtcList, setRtcList} = useContext(DataContext_Student);
    const {rtcListChanged, setRtcListChanged} = useContext(DataContext_Student);

    useEffect(()=>{
        let ele = document.getElementById('videoContainerStyle_up');
        let ele_video = document.getElementById('video-container');

        ele.video_target = ele_video;
        ele.updateSize = updateSize;

        resizeObserver.observe(ele);

        window.loadVideo(Util.getMainUrl(), 'player-container-id');

        const timer1 = setTimeout(()=>{
            processor.doLoad();
        }, 6000)

        const timer2 = setTimeout(()=>{
            //window.pushRTC("", "head-myself");
        }, 2000);

        // build signal socket
        const signalSo = new SignalSocket(Util.getSignalUrl(), "signal");
        signalSo.addOpenListener(()=>{
            signalSo.sendMessage(serial.serial(MsgType.Type_Info, Util.getUserId(), Util.getUserName(), 'student join room'));
        });

        NetSocket.SignalSo = signalSo;

        signalSo.addMessageListener((msg)=>{

            if (msg.t == MsgType.Type_Rtc) {
                setRtcList(msg.data);

                let val = rtcListChanged;
                setRtcListChanged(val++);
            }
        });

        return ()=>{
            resizeObserver.unobserve(ele);
            clearTimeout(timer1);
            clearTimeout(timer2);
        }
    }, []);

    function updateSize() {
        //let tab = document.getElementById('tab_content');
        //let content = tab.getElementsByClassName('ant-tabs-content-holder')[0];
        //let cs = window.getComputedStyle(content);
        //setSize(cs);
    }

    return (
        <div className="student-live-page-container">
            <div className="student-content-container">
                <RtcPlayPage_Student/>
                <div id="videoContainerStyle_up" className="videoContainerStyle_up">
                    <div style={videoContainerStyle} id="video-container">
                        <video id="player-container-id" preload="auto" playsInline webkit-playsinline="true"></video>
                    </div>
                </div>
            </div>
            <div className="student-right-container">
                <div className="student-right-head-container">
                    <canvas id="myCanvas" className="head-t" width="240" height="180"></canvas>
                </div>
                <Link/>
                <div className="tab_content_s">
                    <div className="chat-room-title">
                        <WechatWorkOutlined/>
                        <span className="chat-room-title-text-s">在线{chatCount}人</span>
                        <Switch className="chat-only-teacher" size="small"/>
                        <span className="chat-only-text">只看老师</span>
                    </div>
                    <LineWidget width="100%" height="1px" color="rgb(82, 82, 82)"/>
                    <ChatRoom countFn={setChatCount}/>
                </div>
            </div>

        </div>
    )
}

export default LivePage_Student;

var processor  = {};

processor.doLoad = function doLoad() {
    this.video = document.getElementById('player-container-id_html5_api');
    this.c1 = document.getElementById('myCanvas');
    this.ctx1 = this.c1.getContext('2d');


    let self = this;
    self.timerCallback();
    this.video.addEventListener('play', function() {
        self.timerCallback();
    }, false);
}

processor.timerCallback = function timerCallback() {
    // if (this.video.paused || this.video.ended) {
    //     return;
    // }
    this.computeFrame();
    let self = this;
    setTimeout(function() {
        self.timerCallback();
    }, 50);
}

processor.computeFrame = function computeFrame() {
    this.ctx1.drawImage(this.video, 1436, 0, 240, 180, 0, 0, 240, 180);
};


