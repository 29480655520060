import React from 'react';
import { Menu } from 'antd';
import { useLocation } from 'react-router-dom';
import {
    HomeOutlined,
    FolderOutlined,
    CalendarOutlined,
} from '@ant-design/icons';
import './SideBar.scss';

const menuItems = [
    {
        key: '/',
        icon: <HomeOutlined />,
        label: '主页',
    },
    {
        key: '/cloud_disk',
        icon: <FolderOutlined />,
        label: '空间',
    },
    {
        key: '/schedule',
        icon: <CalendarOutlined />,
        label: '课程表',
    },
];

const SideBar = ({ onTabChanged, userAvatar, onAvatarClick }) => {
    const location = useLocation();

    const handleMenuClick = ({ key }) => {
        onTabChanged(key.replace('/', ''));
    };

    return (
        <div className="sidebar">
            <div className="avatar-container" onClick={onAvatarClick}>
                <img 
                    src={userAvatar || "imgs/head.png"} 
                    alt="avatar" 
                    className="avatar"
                />
            </div>
            <Menu
                mode="vertical"
                theme="dark"
                selectedKeys={[location.pathname]}
                items={menuItems}
                onClick={handleMenuClick}
            />
        </div>
    );
};

export default SideBar; 