import React, { createContext, useState } from 'react';

const DataContext = createContext();

function DataProvider({ children }) {
    const [message, setMessage] = useState('');

    // 创建班级
    const [createCourseDialog, setCreateCourseDialog] = useState(false);

    // 创建讲座
    const [createPublicCourseDialog, setCreatePublicCourseDialog] = useState(false);

    // 创建课节
    const [createLessonDialog, setCreateLessonDialog] = useState(false);

    // 创建班级信息弹框
    const [createCourseDrawer, setCreateCourseDrawer] = useState(false);

    return (
        <DataContext.Provider value={{
            createCourseDialog, setCreateCourseDialog,
            createPublicCourseDialog, setCreatePublicCourseDialog,
            createLessonDialog, setCreateLessonDialog,
            createCourseDrawer, setCreateCourseDrawer
        }}>
            {children}
        </DataContext.Provider>
    );
}

export { DataContext, DataProvider };
