import React, { useState, useRef, useEffect } from 'react';
import {Modal, Button, message, Select, Spin} from 'antd';
import "./CameraSelector.scss"

const { Option } = Select;

const CameraSelector = () => {
    const [selectedCamera, setSelectedCamera] = useState(null);
    const [cameraDevices, setCameraDevices] = useState([]);
    const [videoStream, setVideoStream] = useState(null);
    const videoRef = useRef(null);
    const [activeView, setActiveView] = useState('view-empty');

    // 获取摄像头和麦克风设备列表
    useEffect(() => {
        document.title = "设备选择";
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const cameras = devices.filter(device => device.kind === 'videoinput');
                setCameraDevices(cameras);
                setActiveView('view-main');
            })
            .catch(error => {
                console.error('Error enumerating devices:', error);
            });
    }, []);

    const handleOk = () => {

        window.CallBridge.invokeMethod("device_video", selectedCamera);
        window.CallBridge.invokeMethod("Ok");
    }

    // 选择摄像头
    const handleCameraChange = async deviceId => {
        setSelectedCamera(deviceId);
        if (videoStream) {
            videoStream.getTracks().forEach(track => track.stop());
        }
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId } });
            setVideoStream(stream);
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            }
        } catch (error) {
            console.error('Error accessing camera:', error);
            message.error('无法访问摄像头');
        }
    };

    function renderView() {
        switch (activeView) {
            case 'view-main':
                return (
                    <div className="section">
                        <div className="left">
                            <Select
                                className="select"
                                placeholder="请选择摄像头"
                                onChange={handleCameraChange}
                                defaultValue={0}
                            >
                                {cameraDevices.map(device => (
                                    <Option className="option" key={device.deviceId} value={device.deviceId}>{device.label}</Option>
                                ))}
                                <Option className="option" key={0} value={0}>禁用</Option>
                            </Select>
                        </div>
                        <div className="right">
                            <div style={{width: "240px", height: "180px", backgroundColor: "black"}}>
                                <video ref={videoRef} autoPlay playsInline style={{width: '100%', height: "100%"}}/>
                            </div>
                        </div>
                    </div>
                );
            case 'view-empty':
                return (
                    <div className="device-empty-container">
                        <Spin size="large" />
                    </div>
                );
            default:
                return 'view-main';
        }
    }

    return (
        <div className="cs-container">
            {renderView()}
        </div>
    );
};

export default CameraSelector;
