import React, {useContext, useEffect, useRef, useState} from 'react';
import { flushSync } from 'react-dom';
import './ThumbNails.scss'

function NavItem({children, data, onClick, index}) {

    const ref = useRef();

    const containerStyle = {
        backgroundColor: data.default ? "#3084a9" : "transparent"
    }

    useEffect(()=>{
        if (data.default) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });

        }

    }, [data.default]);

    return (
        <li className="nav-item"
            ref={ref}
            style={containerStyle}
            onClick={()=>{onClick(index, true)}}
        >
            <img className="pic no-event" src={data.img}/>
            <div className="circle">{index+1}</div>
        </li>
    );
}

function NavWidget({lists, updateItem, onClick} )
{
    useEffect(()=>{
        // add Event
        if (window.CallBridge) {
            window.CallBridge.addEventListener("slide_changed", onSlideChanged);
        }

        // remove event
        return ()=>{window.CallBridge.removeEventListener("slide_changed", onSlideChanged)};
    }, [lists])

    function onSlideChanged(index) {
        onItemClick(index-1, false);
    }

    function onItemClick(index, fromInternal) {
        let data = [...lists];
        for (let i = 0; i < data.length; ++i) {
            let item = data[i];
            if (item.default == true) {
                item.default = false;
                updateItem(item, i);
            }
        }

        let item = data[index];
        item.default = true;
        updateItem(item, index);

        if (onClick && fromInternal) {
            onClick(index)
        }
    }

    return (
        <div className="nav-widget">
            {
                lists.map((val, index)=>{
                    return <NavItem onClick={onItemClick} key={index} data={val} index={index}>{val.name}</NavItem>;
                })
            }
        </div>
    );
}

function ThumbNails()
{
    const [navItems, setNavItems] = useState([]);

    useEffect(()=>{
        // add Event
        if (window.CallBridge) {
            window.CallBridge.addEventListener("thumb_list", onListChanged);
            window.CallBridge.invokeMethod("req_send_data");
        }

        // remove event
        return ()=>{
            window.CallBridge.removeEventListener("thumb_list", onListChanged)
        };
    }, [])

    function onListChanged(msg) {
        flushSync(()=>{
            setNavItems(prevItems => {
                return [...prevItems, JSON.parse(msg)]
            });
        });
    }

    function updateItem(newItem, index) {
        flushSync(()=>{
            setNavItems(prevItems => {
                prevItems.map((val, idx)=>{
                    if (idx === index) {
                        prevItems[idx] = newItem;
                    }
                })

                return [...prevItems];
            });
        });
    }

    function onThumbClick(index) {
        if (window.CallBridge) {
            window.CallBridge.invokeMethod("page_change", index+1);
        }
    }

    return (
        <div className="thumb-container">
            <NavWidget lists={navItems} updateItem={updateItem} onClick={onThumbClick}/>
        </div>
    );
}

export default ThumbNails;
