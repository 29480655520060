import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import OSSUpload from '../../Components/net/OSSUpload';
import './AvatarUploadModal.css';

const AvatarUploadModal = ({ 
    visible, 
    onCancel, 
    onSuccess, 
    currentAvatar,
    defaultAvatar 
}) => {
    const [tempAvatar, setTempAvatar] = useState('');

    const handleUploadSuccess = (url) => {
        // 将 http 转换为 https
        const httpsUrl = url.replace('http://', 'https://');
        setTempAvatar(httpsUrl);
    };

    const handleConfirm = () => {
        onSuccess(tempAvatar);
    };

    return (
        <Modal
            title="设置头像"
            open={visible}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    取消
                </Button>,
                <Button 
                    key="confirm" 
                    type="primary" 
                    onClick={handleConfirm}
                    disabled={!tempAvatar}
                >
                    确定
                </Button>
            ]}
            width={800}
        >
            <div className="avatar-upload-container">
                <div className="upload-area">
                    <div className="upload-box">
                        <OSSUpload
                            folder="avatar"
                            accept=".png,.jpg,.jpeg"
                            maxCount={1}
                            onSuccess={handleUploadSuccess}
                        >
                            <div className="upload-content">
                                <UploadOutlined className="upload-icon" />
                                <div className="upload-text">点击上传</div>
                            </div>
                        </OSSUpload>
                    </div>
                    <div className="upload-tip">
                        仅支持5M以内的PNG、JPG、JPEG格式的图片文件
                    </div>
                    <div className="upload-tip">
                        点击左侧头像，可以重新选择头像
                    </div>
                </div>
                <div className="avatar-preview">
                    <div className="preview-title">头像预览</div>
                    <div className="preview-avatar">
                        <img 
                            src={tempAvatar || currentAvatar || defaultAvatar} 
                            alt="40x40" 
                            className="preview-40" 
                        />
                        <div>40px * 40px</div>
                    </div>
                    <div className="preview-avatar">
                        <img 
                            src={tempAvatar || currentAvatar || defaultAvatar} 
                            alt="80x80" 
                            className="preview-80" 
                        />
                        <div>80px * 80px</div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default AvatarUploadModal; 